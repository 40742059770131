import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import { Link } from "../Link";

const links = [
  {
    header: "Services",
    links: [
      { text: "Interior", link: "#" },
      { text: "Fashion", link: "#" },
      { text: "Landscaping", link: "#" }
    ]
  },
  {
    header: "More",
    links: [
      { text: "Videos", link: "/seminars" },
      { text: "Blog", link: "#" },
      { text: "Instagram", link: "#" }
    ]
  },
  {
    header: "VDS",
    links: [{ text: "About", link: "#" }, { text: "Contacts", link: "#" }]
  }
];

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      {links.map(section => (
        <div key={section.header}>
          <h3>{section.header}</h3>
          {section.links.map(link => (
            <Link text={link.text} link={link.link} key={link.text} />
          ))}
        </div>
      ))}
    </div>
    <p className={styles.text}> &copy; Valerie Design Studio</p>
  </footer>
);

Footer.propTypes = {
  links: PropTypes.array
};

export default Footer;
